import React, { useState } from "react"
import { Card, Button, Col, Row } from "react-bootstrap"
import BadgeEtat from "./badge-etat"
import ModalReservation from "./modal-reservation"

const CardReservation = ({ presta }) => {

    const [modalShow, setModalShow] = useState(false);
    const [prestaToReserve, setPrestaToReserve] = useState({});

    const onReserveClick = (modalShow = false, prestaToReserve) => {
        setPrestaToReserve(prestaToReserve)
        setModalShow(modalShow)
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h5 className="mt-2">Prix</h5>
                    <div>{"Adultes: " + presta.prix.adultes + "€"}</div>
                    <div>{"Enfants: " + presta.prix.enfants + "€"}</div>
                    <h5 className="mt-2">Prochaines dates</h5>
                    {presta.nextDepartures.map((el, i) =>
                        <div key={i}>
                            <Row>
                                <Col className="col-7 m-0 pr-0">{el.date + " " + el.time}</Col>
                                <Col className="col-5 m-0 p-0"><BadgeEtat key={"b" + i} short={true} etat={el.etat} /></Col>
                            </Row>
                        </div>
                    )}
                    <div className="mt-2 text-center">
                        {presta.nextDepartures.length > 0 ?
                            <Button className="reserver" onClick={() => onReserveClick(true, presta)}>Réserver</Button>
                            :
                            <Button href='/contact'>Contacter</Button>
                        }
                    </div>
                </Card.Body>
                <ModalReservation
                    show={modalShow}
                    centered
                    backdrop="static"
                    keyboard={false}
                    prestatoreserve={prestaToReserve}
                    onHide={() => setModalShow(false)}
                />
            </Card>
        </>
    )

}

export default CardReservation



