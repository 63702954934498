import React, { useState, useEffect } from "react"
import { Container, Carousel, Row, Col } from "react-bootstrap"
import Gallery from "react-grid-gallery"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PrestationAvis from "../components/prestation-avis"
import Carte from "../components/carte"
import DetailsRando from "../components/icons-caracteristique-rando"
import CardReservation from "../components/card-reservation"
import { getAllNextDepartures } from "../services/utils"

const PrestationPage = ({ pageContext }) => {

    const [prestaWithNextDepartures, setPrestaWithNextDepartures] = useState(undefined);

    useEffect(() => {
        const { presta } = pageContext
        const nextDepartures = require("../../data/next-departures.json")
        const allNextDepartures = getAllNextDepartures(nextDepartures.filter(el => el.titre === presta.titre))
        const prestaWithNextDepartures = { ...presta, nextDepartures: allNextDepartures }
        setPrestaWithNextDepartures(prestaWithNextDepartures)
    }, [])

    if (!prestaWithNextDepartures) { return (<></>) }
    return (
        <Layout>
            <SEO title={prestaWithNextDepartures.titre} />
            <Container>
                <h1 className="text-center mt-4" >
                    {prestaWithNextDepartures.titre}
                </h1>
                <h5 className="text-center">
                    {prestaWithNextDepartures.location}
                </h5>

                <DetailsRando presta={prestaWithNextDepartures} imgWidth={"2rem"} spaceImgTxt={"1rem"} isGeneratedPage={true} />

                <Carousel className="mt-4">
                    {prestaWithNextDepartures.imgs.slice(0, 3).map((el, i) =>
                        <Carousel.Item key={i}><img style={{ height: "30rem", objectFit: "cover" }} className="d-block w-100" src={'../images/' + el} alt={""} /></Carousel.Item>
                    )}
                </Carousel>

                <div className="mt-4" dangerouslySetInnerHTML={{ __html: prestaWithNextDepartures.longdescription }} />

                <h1 className="mt-4">Détails de la randonnée</h1>
                <Row className="justify-content-center">
                    <Col className="col-12 col-lg-8 col-xl-9">
                        <div dangerouslySetInnerHTML={{ __html: prestaWithNextDepartures.details }} />
                    </Col>
                    <Col className="col-7 col-sm-7 col-md-5 col-lg-4 col-xl-3">
                        <CardReservation presta={prestaWithNextDepartures} />
                    </Col>
                </Row>

                {prestaWithNextDepartures.pointDepart && <div>
                    <h1 className="mt-4">Point de départ</h1>
                    <div dangerouslySetInnerHTML={{ __html: prestaWithNextDepartures.pointDepart.text }} />
                    <div className="mt-4">
                        <Carte loc={prestaWithNextDepartures.pointDepart.loc} height={"20rem"} zoom={10} />
                    </div>
                </div>}

                {prestaWithNextDepartures.avis && prestaWithNextDepartures.avis.length > 0 ? <PrestationAvis avis={prestaWithNextDepartures.avis} /> : <div></div>}

                <div className="mt-4" style={{
                    display: "block",
                    minHeight: "1px",
                    width: "100%",
                    overflow: "auto"
                }}>
                    <Gallery
                        showCloseButton={false}
                        backdropClosesModal={true}
                        rowHeight={150}
                        showImageCount={false}
                        enableImageSelection={false}
                        // thumbnailStyle={() => {
                        //     return ({ border: '5px solid red' })
                        // }}
                        images={prestaWithNextDepartures.imgs.map(el => ({
                            src: '../images/' + el, thumbnail: '../images/' + el, thumbnailWidth: 400, thumbnailHeight: 200
                        }))}
                    />
                </div>

            </Container>

        </Layout >
    )
}

export default PrestationPage