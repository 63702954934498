import React from "react"

const PrestationAvis = ({ avis }) => {

    return (
        <>
            <h1 style={{ marginTop: "2rem" }}>Avis</h1>
            { avis.map((el, i) => <div key={i} style={{ padding: "1rem" }}><i>"{el.text}"</i><div>{el.auteur}</div></div>)}
        </>
    )
}

export default PrestationAvis